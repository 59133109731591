import { useStaticQuery } from "gatsby"
import React from "react"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"
import NewsSlider from "../NewsSlider/NewsSlider"
import "./TeamBlogs.scss"

const TeamBlogs = ({ teamInfo }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBlog(
        filter: { publish: { eq: true } }
        sort: { fields: date, order: ASC }
      ) {
        edges {
          node {
            title
            slug
            strapi_id
            read_time
            date
            tile_image {
              url
            }
            ggfx_results {
              src_cftle
              transforms {
                url
                transform
                format
              }
            }
            imagetransforms {
              tile_image_Transforms
            }
            published_by {
              image {
                url
              }
              name
              phone
              designation
              email
            }
          }
        }
      }
    }
  `)
  const teamBlogs = data.allStrapiBlog.edges

  const publishedMember = teamBlogs.filter(
    blog =>
      blog.node?.published_by?.name?.toLowerCase() ===
      teamInfo?.name?.toLowerCase()
  )

  return (
    <>
      {publishedMember?.length > 0 && (
        <div className="team-blogs-wrapper">
          <div className="team-blogs">
            <NewsSlider
              className="team-news-details-slider"
              data={publishedMember}
              title="Related Content"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default TeamBlogs
