import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import PropertySliderCard from "../PropertySliderCard/PropertySliderCard"
import "./TeamProperty.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const TeamProperty = ({ teamInfo }) => {
  const [teamProperty, setTeamProperty] = useState([])
  const [loading, setLoading] = useState(false)
  const {isMobile} = useDeviceMedia()


  useEffect(() => {
    if (!_.isEmpty(teamProperty)) return

    const url = `${process.env.GATSBY_STRAPI_SRC}/api/properties?filters[negotiator_id][$eq]=${teamInfo?.negotiator_id}&filters[publish][$eq]=true&sort=updatedAt:desc&filters[status][$eq]=For Sale&filters[status][$eq]=To Let&populate[ggfx_results]=*`

    setLoading(true)
    fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        setTeamProperty(data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  if (loading) {
    return (
      <Container>
        <p></p>
      </Container>
    )
  }

  if (_.isEmpty(teamProperty)) return null

  const splitName = teamInfo?.name?.split(" ")
  const firstName = splitName?.length > 0 && splitName[0]

  return (
    <>
      {teamProperty?.length > 0 && (
        <div className="team-property-wrapper">
          <Container>
            <div className="team-property-card">
              <h2 className="team-prop-title">{firstName}'s Listings</h2>
              <CustomSlider
                showArrows
                className="team-prop-slider"
                slidecount={isMobile?1:4}
              >
                {teamProperty.length > 0 &&
                  teamProperty.map((item, index) => {
                    const imageUrl =
                      item.attributes?.images?.length > 0 &&
                      item.attributes.images[0]

                    const imageLength = item.attributes?.images?.length

                    return (
                      <PropertySliderCard
                        title={item.attributes?.title}
                        image={imageUrl}
                        imageCount={imageLength}
                        imageList={item.attributes?.images}
                        display_address={item.attributes?.display_address}
                        price={item.attributes?.price}
                        bathroom={item.attributes?.bathroom}
                        bedroom={item.attributes?.bedroom}
                        floorarea_min={item.attributes?.floorarea_min}
                        floorarea_type={item.attributes?.floorarea_type}
                        search_type={item.attributes?.search_type}
                        department={item.attributes?.department}
                        strapi_id={item?.id}
                        slug={item.attributes?.slug}
                        crm_id={item.attributes?.crm_id}
                        status={item.attributes?.status}
                        imagetransforms={item.attributes?.imagetransforms}
                        slider_name="team-property-slider"
                        ggfx_results={item.attributes?.ggfx_results}
                      />
                    )
                  })}
              </CustomSlider>
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

export default TeamProperty
